import {
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useAppDispatch } from "modules/core";
import { AllTransactionsResponse, Transaction } from "openapi";
import { useEffect, useState } from "react";
import { findAllTransactions } from "../slices";

export const BillingHistory = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const { payload } = (await dispatch(findAllTransactions())) as {
          payload: AllTransactionsResponse;
        };
        setTransactions(payload.data);
      } catch (error) {
        console.error("Failed to fetch transactions", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Billing History
      </Typography>
      <Card>
        <CardContent>
          {loading ? (
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Paypal Subscription ID</TableCell>
                    <TableCell>Paypal Transaction ID</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((transaction) => (
                    <TableRow key={transaction.id}>
                      <TableCell>{transaction.subscriptionId}</TableCell>
                      <TableCell>{transaction.id}</TableCell>
                      <TableCell>
                        {new Date(transaction.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{transaction.amount}</TableCell>
                      <TableCell>
                        <Chip label={transaction.status} size="small" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </>
  );
};
