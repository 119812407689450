import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <Box sx={{ p: 1 }}>
      <Stack spacing={0.5} alignItems="center">
        <Stack direction="row" spacing={1}>
          <Link
            to="/trust/privacy"
            style={{
              color: "inherit",
              textDecoration: "none",
              fontSize: "0.75rem"
            }}
          >
            Privacy
          </Link>
          <Typography sx={{ fontSize: "0.75rem" }}>•</Typography>
          <Link
            to="/trust/terms"
            style={{
              color: "inherit",
              textDecoration: "none",
              fontSize: "0.75rem"
            }}
          >
            Terms
          </Link>
        </Stack>
        <Typography sx={{ fontSize: "0.75rem", color: "text.secondary" }}>
          TypeArt © {new Date().getFullYear()}
        </Typography>
      </Stack>
    </Box>
  );
};
