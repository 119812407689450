import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ReactNode } from "react";

interface Props {
    open: boolean;
    title: string;
    message: ReactNode;
    onConfirm: () => void;
    onClose: () => void;
}

export const PlanConfirmationDialog = ({
    open,
    title,
    message,
    onConfirm,
    onClose
}: Props) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={() => {
                onConfirm();
                onClose();
            }} autoFocus>
                Confirm
            </Button>
        </DialogActions>
    </Dialog>
); 