import { AccountTier, SubscriptionState } from "@monorepo/constants";
import { useMemo } from "react";
import { PlainPlanEntity, PlainSubscriptionEntity } from "../entities";
import { PlanPermissions } from "../types";
import { usePlan } from "./use-plan.hook";

interface Props {
  plan: PlainPlanEntity;
  currentPlan: PlainPlanEntity;
  currentSubscription: PlainSubscriptionEntity;
}

export const usePlanPermissions = ({
  plan,
  currentPlan,
  currentSubscription
}: Props) => {
  const { isCurrentPlan } = usePlan({ plan, currentPlan });
  const currentSubscriptionState =
    currentSubscription.state as SubscriptionState;

  const canSubscribe = useMemo(() => {
    if (plan.tier === AccountTier.Trial) {
      return false;
    }

    if (currentPlan.tier === AccountTier.Trial) {
      return true;
    }

    if (currentSubscriptionState === SubscriptionState.Cancelled) {
      return true;
    }

    return false;
  }, [currentPlan, currentSubscription, isCurrentPlan]);

  const canReviseSubscription = useMemo(() => {
    if (
      plan.tier === AccountTier.Trial ||
      currentPlan.tier === AccountTier.Trial
    ) {
      return false;
    }

    if (isCurrentPlan) {
      return false;
    }

    if (
      [
        SubscriptionState.Active,
        SubscriptionState.Paused,
        SubscriptionState.Expired
      ].includes(currentSubscriptionState)
    ) {
      return true;
    }

    return false;
  }, [currentPlan]);

  const canSuspendSubscription = useMemo(() => {
    if (plan.tier === AccountTier.Trial) {
      return false;
    }

    if (isCurrentPlan) {
      if ([SubscriptionState.Active].includes(currentSubscriptionState)) {
        return true;
      }
    }

    return false;
  }, [currentPlan]);

  const canActivateSubscription = useMemo(() => {
    if (plan.tier === AccountTier.Trial) {
      return false;
    }

    if (isCurrentPlan) {
      if (
        [SubscriptionState.Paused, SubscriptionState.Expired].includes(
          currentSubscriptionState
        )
      ) {
        return true;
      }
    }

    return false;
  }, [currentPlan]);

  const canCancelSubscription = useMemo(() => {
    if (plan.tier === AccountTier.Trial) {
      return false;
    }

    if (isCurrentPlan) {
      if (
        [SubscriptionState.Active, SubscriptionState.Paused].includes(
          currentSubscriptionState
        )
      ) {
        return true;
      }
    }

    return false;
  }, [currentPlan]);

  return {
    canSubscribe,
    canReviseSubscription,
    canSuspendSubscription,
    canActivateSubscription,
    canCancelSubscription
  } satisfies PlanPermissions;
};
