import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid2 as Grid,
  Stack,
  Typography
} from "@mui/material";
import { PlainPlanEntity, PlainSubscriptionEntity } from "../entities";
import { usePlan } from "../hooks";
import { DaysLeft } from "./DaysLeft";
import { PlanButtons } from "./PlanButtons";

interface Props {
  plan: PlainPlanEntity;
  currentPlan: PlainPlanEntity;
  currentSubscription: PlainSubscriptionEntity;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const Plan = ({
  plan,
  currentPlan,
  currentSubscription,
  loading,
  setLoading
}: Props) => {
  const { isCurrentPlan, wrapperStyles } = usePlan({
    plan,
    currentPlan
  });

  return (
    <Grid key={plan.id} size={{ xs: 12, md: 4 }}>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          ...wrapperStyles
        }}
      >
        {isCurrentPlan && (
          <Stack>
            <Chip
              label="Current Plan"
              color="primary"
              size="small"
              sx={{ position: "absolute", right: 8, top: 8 }}
            />
            <Chip
              label={currentSubscription.state}
              color="secondary"
              size="small"
              sx={{
                position: "absolute",
                right: 8,
                top: 40
              }}
            />
            <DaysLeft
              currentPlan={currentPlan}
              currentSubscription={currentSubscription}
            />
          </Stack>
        )}
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h5" gutterBottom>
            {plan.tier}
          </Typography>
          {plan.priceAmount ? (
            <Typography variant="h4" color="primary" gutterBottom>
              ${plan.priceAmount}
              <Typography variant="caption" color="text.secondary">
                /month
              </Typography>
            </Typography>
          ) : (
            <Typography variant="h4" color="primary" gutterBottom>
              Free
            </Typography>
          )}
          <Box sx={{ mt: 2 }}>
            {plan.limitations.maxUsers && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CheckCircleIcon
                  sx={{ mr: 1, fontSize: 20, color: "success.main" }}
                />
                <Typography variant="body2">
                  {plan.limitations.maxUsers} users (Will be supported soon)
                </Typography>
              </Box>
            )}
            {plan.limitations.maxAppUsages && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CheckCircleIcon
                  sx={{ mr: 1, fontSize: 20, color: "success.main" }}
                />
                <Typography variant="body2">
                  {plan.limitations.maxAppUsages.toLocaleString("en-US")} app
                  usages/month
                </Typography>
              </Box>
            )}
            {plan.limitations.maxTokens && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CheckCircleIcon
                  sx={{ mr: 1, fontSize: 20, color: "success.main" }}
                />
                <Typography variant="body2">
                  {plan.limitations.maxTokens.toLocaleString("en-US")}{" "}
                  tokens/month
                </Typography>
              </Box>
            )}
            {plan.limitations.trialDays && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CheckCircleIcon
                  sx={{ mr: 1, fontSize: 20, color: "success.main" }}
                />
                <Typography variant="body2">
                  {plan.limitations.trialDays} days trial
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
        <PlanButtons
          plan={plan}
          currentPlan={currentPlan}
          currentSubscription={currentSubscription}
          loading={loading}
          setLoading={setLoading}
        />
      </Card>
    </Grid>
  );
};
