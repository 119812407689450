import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { SpinnerLoader } from "modules/common/components";
import { useAppSelector } from "modules/core";
import { useState } from "react";
import { BillingHistory, Plan } from "../components";
import {
  selectCurrentPlan,
  selectCurrentSubscription,
  selectPlans
} from "../slices";

export const Billing = () => {
  const plans = useAppSelector(selectPlans);
  const currentPlan = useAppSelector(selectCurrentPlan);
  const currentSubscription = useAppSelector(selectCurrentSubscription);
  const [loading, setLoading] = useState(false);

  if (!currentPlan || !currentSubscription) {
    return <SpinnerLoader />;
  }

  return (
    <PayPalScriptProvider
      deferLoading={true}
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || "",
        components: "buttons",
        environment:
          process.env.NODE_ENV === "production" ? "production" : "sandbox",
        intent: "subscription",
        locale: "en_US",
        vault: true
      }}
    >
      <Box>
        <Typography variant="h4" gutterBottom>
          Billing & Subscription
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Available Plans
        </Typography>
        <Grid container spacing={3}>
          {plans.map((plan) => (
            <Plan
              key={plan.id}
              plan={plan}
              currentPlan={currentPlan}
              currentSubscription={currentSubscription}
              loading={loading}
              setLoading={setLoading}
            />
          ))}
        </Grid>

        {/* Billing History */}
        <BillingHistory />
      </Box>
    </PayPalScriptProvider>
  );
};
