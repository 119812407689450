import {
  faCreditCard,
  faGaugeHigh,
  faGridDividers,
  faStore,
  faUser
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  alpha,
  Box,
  Container,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Toolbar,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { SharedLayout } from "./SharedLayout";

const DRAWER_WIDTH = 240;

const menuItems = [
  { text: "Dashboard", icon: faGaugeHigh, path: "" },
  { text: "Account", icon: faUser, path: "account" },
  { text: "Billing", icon: faCreditCard, path: "billing" },
  { text: "My Apps", icon: faGridDividers, path: "my-apps" },
  { text: "Marketplace", icon: faStore, path: "marketplace" }
];

interface MenuItemsProps {
  onItemClick: (path: string) => void;
  theme: Theme;
}

const MenuItems = ({ onItemClick, theme }: MenuItemsProps) => (
  <List
    sx={{
      display: "flex",
      flexDirection: { xs: "row", md: "column" },
      flexWrap: "wrap",
      justifyContent: "center"
    }}
  >
    {menuItems.map((item) => (
      <ListItem
        key={item.text}
        onClick={() => onItemClick(item.path)}
        sx={{
          "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            cursor: "pointer"
          },
          width: { xs: "auto", md: "100%" },
          justifyContent: "center",
          px: { xs: 2, md: 3 }
        }}
      >
        <ListItemIcon sx={{ minWidth: { xs: "24px", md: "40px" } }}>
          <FontAwesomeIcon icon={item.icon} size="lg" />
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          sx={{
            "& .MuiTypography-root": {
              fontSize: { xs: "0.9rem", md: "1rem" }
            }
          }}
        />
      </ListItem>
    ))}
  </List>
);

export const PlatformLayout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <SharedLayout>
      <CssBaseline />
      <Header />
      <Container maxWidth="xl">
        {isMdUp ? (
          // Desktop layout with permanent drawer
          <Drawer
            variant="permanent"
            sx={{
              [`& .MuiDrawer-paper`]: {
                background: "inherit",
                left: "auto",
                width: DRAWER_WIDTH,
                zIndex: 0
              }
            }}
          >
            <Toolbar />
            <Box sx={{ overflow: "auto" }}>
              <MenuItems onItemClick={navigate} theme={theme} />
            </Box>
            <Footer />
          </Drawer>
        ) : null}

        <Box
          sx={{
            p: 4,
            marginLeft: { md: `${DRAWER_WIDTH}px`, xs: 0 }
          }}
        >
          <Toolbar />
          {isMdDown && (
            <Box sx={{ mb: 4 }}>
              <MenuItems onItemClick={navigate} theme={theme} />
            </Box>
          )}
          <Outlet />
        </Box>
      </Container>
    </SharedLayout>
  );
};
