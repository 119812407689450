import CircleIcon from "@mui/icons-material/Circle";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid2 as Grid,
  TextField,
  Typography
} from "@mui/material";
import { useAppDispatch, withWrapper } from "modules/core";
import { FormEvent, useCallback, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { logInAction, logInOtpAction } from "../slices";

export const LogIn = withWrapper(() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");

  const resetOtp = useCallback(() => {
    setOtp("");
    setShowOtp(false);
  }, []);

  useEffect(() => {
    if (otp.length === 6) {
      const form = document.querySelector("form");
      if (form) {
        form.requestSubmit();
      }
    }
  }, [otp]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const data = new FormData(event.currentTarget);
    const phone = data.get("phone")?.toString();

    try {
      if (phone) {
        if (showOtp) {
          if (otp) {
            await dispatch(logInOtpAction({ phone, otp })).unwrap();
            navigate("/platforms/reminders");
          }
        } else {
          await dispatch(logInAction({ phone })).unwrap();
          setShowOtp(true);
        }
      }
    } catch {}
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log In
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <TextField
                autoComplete="phone"
                autoFocus
                fullWidth
                label="Phone Number"
                margin="normal"
                name="phone"
                required
                type="tel"
                onChange={resetOtp}
              />
            </Grid>
            <Grid
              size={{ xs: 12 }}
              visibility={showOtp ? "visible" : "hidden"}
              height={showOtp ? "auto" : 0}
            >
              <Divider>OTP</Divider>
              <Typography
                color="text.secondary"
                textAlign="center"
                variant="body2"
              >
                Enter the 6-digit code sent to your phone number
              </Typography>
              <OtpInput
                numInputs={6}
                onChange={setOtp}
                renderSeparator={
                  <CircleIcon
                    color="disabled"
                    sx={{ mt: 1 }}
                    style={{ fontSize: "12px" }}
                  />
                }
                renderInput={(otpProps) => (
                  <TextField
                    id="otp"
                    margin="normal"
                    name="otp"
                    slotProps={{
                      htmlInput: { ...otpProps, inputMode: "numeric" }
                    }}
                    sx={{ width: 48, mx: 0.5 }}
                    type="number"
                    variant="outlined"
                  />
                )}
                shouldAutoFocus={showOtp}
                value={otp}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" checked />}
                label="Remember me"
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Log In
          </Button>
        </Box>
      </Box>
    </Container>
  );
});
