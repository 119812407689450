import { PaymentVendor } from "@monorepo/constants";
import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Button, Stack, Typography } from "@mui/material";
import {
  DISPATCH_ACTION,
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { findCurrentAccount } from "modules/authentication";
import { useAppDispatch } from "modules/core";
import { ReactNode, useEffect, useState } from "react";
import { PlainPlanEntity, PlainSubscriptionEntity } from "../entities";
import { usePlan, usePlanPermissions } from "../hooks";
import {
  activateSubscription,
  cancelSubscription,
  findAllSubscriptions,
  reviseSubscription,
  subscribePlan,
  suspendSubscription
} from "../slices";
import { PlanConfirmationDialog } from "./PlanConfirmationDialog";

interface Props {
  plan: PlainPlanEntity;
  currentPlan: PlainPlanEntity;
  currentSubscription: PlainSubscriptionEntity;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const PlanButtons = ({
  plan,
  currentPlan,
  currentSubscription,
  loading,
  setLoading
}: Props) => {
  const dispatch = useAppDispatch();
  const [{ options }, paypalDispatch] = usePayPalScriptReducer();
  const { vendorPlanId } = usePlan({ plan, currentPlan });
  const permissions = usePlanPermissions({
    plan,
    currentPlan,
    currentSubscription
  });

  const [dialogConfig, setDialogConfig] = useState<{
    open: boolean;
    title: string;
    message: ReactNode;
    onConfirm: () => void;
  }>({
    open: false,
    title: "",
    message: <></>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm: () => {}
  });

  const closeDialog = () => {
    setDialogConfig((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    paypalDispatch({
      type: DISPATCH_ACTION.RESET_OPTIONS,
      value: { ...options, intent: "subscription" }
    });
  }, []);

  const handleReviseSubscription = () => {
    setDialogConfig({
      open: true,
      title: "Revise Subscription",
      message: (
        <Typography variant="body1">
          The new price is effective starting on the next billing cycle, but we
          will update your current plan immediately.
          <br />
          Are you sure you want to revise your subscription?
          <br />
          <br />
          It might take a few minutes for the changes to take effect.
        </Typography>
      ),
      onConfirm: () => dispatch(reviseSubscription({ tier: plan.tier }))
    });
  };

  const handleSuspendSubscription = () => {
    setDialogConfig({
      open: true,
      title: "Suspend Subscription",
      message: (
        <Typography variant="body1">
          When a subscription is suspended, PayPal will stop processing payments
          until it is reactivated.
          <br />
          Are you sure you want to suspend your subscription?
          <br />
          You can reactivate it later.
          <br />
          <br />
          It might take a few minutes for the changes to take effect.
        </Typography>
      ),
      onConfirm: () => dispatch(suspendSubscription())
    });
  };

  const handleActivateSubscription = () => {
    setDialogConfig({
      open: true,
      title: "Activate Subscription",
      message: (
        <Typography variant="body1">
          Are you sure you want to reactivate your subscription? You will be
          billed according to your plan.
          <br />
          <br />
          It might take a few minutes for the changes to take effect.
        </Typography>
      ),
      onConfirm: () => dispatch(activateSubscription())
    });
  };

  const handleCancelSubscription = () => {
    setDialogConfig({
      open: true,
      title: "Cancel Subscription",
      message: (
        <Typography variant="body1">
          Are you sure you want to cancel your subscription? This action cannot
          be undone.
          <br />
          <br />
          It might take a few minutes for the changes to take effect.
        </Typography>
      ),
      onConfirm: () => dispatch(cancelSubscription())
    });
  };

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <PlanConfirmationDialog
        open={dialogConfig.open}
        title={dialogConfig.title}
        message={dialogConfig.message}
        onConfirm={dialogConfig.onConfirm}
        onClose={closeDialog}
      />

      {permissions.canSubscribe && (
        <PayPalButtons
          disabled={loading}
          onClick={() => setLoading(true)}
          createSubscription={(_, actions) =>
            actions.subscription.create({ plan_id: vendorPlanId })
          }
          onApprove={async (data, _) => {
            try {
              await dispatch(
                subscribePlan({
                  vendor: PaymentVendor.PayPal,
                  tier: plan.tier,
                  orderID: data.orderID || "",
                  purchaseToken: data.facilitatorAccessToken || "",
                  vendorSubscriptionId: data.subscriptionID || ""
                })
              ).unwrap();

              await Promise.all([
                dispatch(findCurrentAccount()).unwrap(),
                dispatch(findAllSubscriptions()).unwrap()
              ]);
            } catch (error) {
              console.error(error);
            } finally {
              setLoading(false);
            }
          }}
          onCancel={() => setLoading(false)}
          onError={() => setLoading(false)}
          style={{ label: "subscribe" }}
        />
      )}

      {permissions.canReviseSubscription && (
        <Button
          disabled={loading}
          variant="contained"
          startIcon={<ChangeCircleIcon />}
          onClick={handleReviseSubscription}
        >
          Revise Subscription
        </Button>
      )}

      {permissions.canSuspendSubscription && (
        <Button
          disabled={loading}
          variant="outlined"
          startIcon={<PauseCircleIcon />}
          onClick={handleSuspendSubscription}
        >
          Suspend Subscription
        </Button>
      )}

      {permissions.canActivateSubscription && (
        <Button
          disabled={loading}
          variant="contained"
          startIcon={<PlayCircleIcon />}
          onClick={handleActivateSubscription}
        >
          Activate Subscription
        </Button>
      )}

      {permissions.canCancelSubscription && (
        <Button
          disabled={loading}
          variant="outlined"
          color="error"
          startIcon={<CancelIcon />}
          onClick={handleCancelSubscription}
        >
          Cancel Subscription
        </Button>
      )}
    </Stack>
  );
};
