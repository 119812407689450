import { AccountTier, PaymentVendor } from "@monorepo/constants";
import { Theme } from "@mui/material";
import { SystemCssProperties } from "@mui/system";
import { useMemo } from "react";
import { PlainPlanEntity } from "../entities";

interface Props {
  plan: PlainPlanEntity;
  currentPlan: PlainPlanEntity;
}
export const usePlan = ({ plan, currentPlan }: Props) => {
  const isCurrentPlan = plan.id === currentPlan.id;
  const wrapperStyles = useMemo(() => {
    const styles: SystemCssProperties<Theme> = {};

    if (isCurrentPlan) {
      styles.border = (theme) => `2px solid ${theme.palette.primary.main}`;
    }

    if (plan.tier === AccountTier.Trial) {
      if (currentPlan?.tier !== AccountTier.Trial) {
        styles.opacity = 0.5;
      }
    }

    return styles;
  }, [plan, currentPlan]);

  const vendorPlanId = plan.vendorPlans[PaymentVendor.PayPal];

  return {
    isCurrentPlan,
    vendorPlanId,
    wrapperStyles
  };
};
