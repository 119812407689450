import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { logout } from "modules/authentication";
import { apiCall } from "modules/core";
import { normalize } from "normalizr";
import {
  MonetizationService,
  ReviseDto,
  SubscribeDto,
  SubscriptionEntity
} from "openapi";
import { subscriptionSchema } from "store";
import { RootState } from "types";
import {
  NormalizedSubscriptionEntity,
  PlainSubscriptionEntity,
  extractSubscriptionPlainData
} from "../entities";

const {
  subscriptionsControllerFindAll,
  subscriptionsControllerFindAllTransactions,
  subscriptionsControllerSubscribe,
  subscriptionsControllerRevise,
  subscriptionsControllerSuspend,
  subscriptionsControllerActivate,
  subscriptionsControllerCancel
} = MonetizationService;

// Async thunks
export const findAllSubscriptions = createAsyncThunk(
  "subscriptions/findAll",
  async (_, { rejectWithValue }) =>
    await apiCall(subscriptionsControllerFindAll, rejectWithValue)
);

export const findAllTransactions = createAsyncThunk(
  "subscriptions/findAllTransactions",
  async (_, { rejectWithValue }) =>
    await apiCall(subscriptionsControllerFindAllTransactions, rejectWithValue)
);

export const subscribePlan = createAsyncThunk(
  "subscriptions/subscribe",
  async (body: SubscribeDto, { rejectWithValue }) =>
    await apiCall(subscriptionsControllerSubscribe, rejectWithValue, body)
);

export const reviseSubscription = createAsyncThunk(
  "subscriptions/revise",
  async (body: ReviseDto, { rejectWithValue }) =>
    await apiCall(subscriptionsControllerRevise, rejectWithValue, body)
);

export const suspendSubscription = createAsyncThunk(
  "subscriptions/suspend",
  async (_, { rejectWithValue }) =>
    await apiCall(subscriptionsControllerSuspend, rejectWithValue)
);

export const activateSubscription = createAsyncThunk(
  "subscriptions/activate",
  async (_, { rejectWithValue }) =>
    await apiCall(subscriptionsControllerActivate, rejectWithValue)
);

export const cancelSubscription = createAsyncThunk(
  "subscriptions/cancel",
  async (_, { rejectWithValue }) =>
    await apiCall(subscriptionsControllerCancel, rejectWithValue)
);

// Entity adapter
const entityAdapter = createEntityAdapter<PlainSubscriptionEntity>({
  sortComparer: (entityA, entityB) => entityB.id - entityA.id
});

const initialState = entityAdapter.getInitialState();

// Define the slice
const slice = createSlice({
  name: "subscriptionsEntities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);

    builder.addCase(findAllSubscriptions.fulfilled, (state, action) => {
      const normalizedData = normalize<
        SubscriptionEntity,
        {
          subscriptions: Record<string, NormalizedSubscriptionEntity>;
        }
      >(action.payload.data, [subscriptionSchema]);

      const nextEntities = Object.values(
        normalizedData.entities.subscriptions || []
      ).map(extractSubscriptionPlainData);

      entityAdapter.upsertMany(state, nextEntities);
    });
  }
});

export const subscriptionsEntitiesReducer = slice.reducer;

// Export the standard reducers and the reducer function
export const {
  selectAll: selectSubscriptions,
  selectEntities: selectSubscriptionsEntities,
  selectById: selectSubscriptionById
} = entityAdapter.getSelectors(
  (state: RootState) => state.subscriptionsEntities
);

// Custom selector
export const selectCurrentSubscription = createSelector(
  selectSubscriptions,
  (subscriptions) =>
    subscriptions.find((subscription) => subscription.isDefault)
);
